<template>
  <div class="consolidated-container">
    <div class="queryItems mb10">
      <el-input size="small" class="mr15" v-model="queryCode" @clear="getData" clearable style="width: 30rem" :placeholder="$t('domestic.placeholder')"></el-input>
      <el-button size="small" class="mr15" type="primary" icon="el-icon-search" @click="getData">{{ $t('domestic.search') }}</el-button>
      <download-excel
          class="export-excel-wrapper"
          :data="tableData"
          :fields="fields"
          :name="timestampName"
          worksheet="sheet1"
      >
        <el-button size="small" icon="el-icon-upload2" type="primary">{{ $t('order.export') }}</el-button>
      </download-excel>
    </div>
    <div class="queryItems">
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">{{ $t('order.warehouse') }}：</div>
        <el-select size="small" v-model="querys.warehouse" clearable style="width: 20rem">
          <el-option
              v-for="item in options['warehouse']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">{{ $t('order.goodstype') }}：</div>
        <el-select size="small" v-model="querys.goodsType" clearable style="width: 20rem">
          <el-option
              v-for="item in options['goodsType']"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15 mb10">
        <div class="default-font">{{ $t('domestic.CreateTime') }}：</div>
        <el-date-picker
            size="small"
            v-model="querys.dateRange"
            type="datetimerange"
            :range-separator="$t('order.separator')"
            :start-placeholder="$t('order.startTime')"
            :end-placeholder="$t('order.endTime')">
        </el-date-picker>
      </div>
    </div>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="name in names" :label="$t(`domestic.${name}`)" :name="name" :key="name">
        <el-table
            :data="tableData"
            height="360px"
            max-height="360px"
            size="mini"
            border
            ref="multipleTable"
            style="width: 100%"
            v-loading="loading"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" :selectable="checkSelectable" width="55"></el-table-column>
          <el-table-column :label="$t('goodcard.code')" show-overflow-tooltip prop="Code" align="center" width="150"></el-table-column>
          <el-table-column :label="$t('goodcard.GoodsTypeName')" prop="GoodsTypeName" align="center"></el-table-column>
          <el-table-column :label="$t('goodcard.Account')" prop="Account" align="center"></el-table-column>
          <el-table-column :label="$t('domestic.warehousename')" prop="WareHouseName" align="center"></el-table-column>
          <el-table-column :label="$t('domestic.status')" align="center">
            <template slot-scope="scope">
              <div class="arrived-box" v-if="scope.row.IsStorage">{{ $t('domestic.arrived') }}</div>
              <div class="unarrive-box" v-else>{{ $t('domestic.unarrive') }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('mergerCard.totalSurcharge')" prop="Surcharge" align="center"></el-table-column>
          <el-table-column :label="$t('goodcard.goodsname')" show-overflow-tooltip prop="GoodsName" align="center"></el-table-column>
          <el-table-column :label="$t('goodcard.weight')" prop="Weight" align="center"></el-table-column>
          <el-table-column :label="$t('goodcard.volumeweight')" prop="VolumeWeight" align="center"></el-table-column>
          <el-table-column :label="$t('goodcard.size')" show-overflow-tooltip align="center" width="135">
            <template slot-scope="scope">
              <span>{{`${scope.row.Length}*${scope.row.Width}*${scope.row.Height}`}}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              :label="$t('domestic.operate')"
              align="center">
            <template slot-scope="scope">
              <el-button v-if="activeName !== 'claim'" type="text" size="mini" @click="editGood(scope.row)">{{ $t('domestic.edit') }}</el-button>
              <el-button v-if="activeName !== 'claim'" type="text" size="mini" @click="deleteGood(scope.row)">{{ $t('domestic.delete') }}</el-button>
              <el-button v-if="scope.row.Images && activeName !== 'claim'" type="text" size="mini" @click="showImage(scope.row)">{{ $t('domestic.showImage') }}</el-button>
              <el-button v-if="activeName === 'claim'" type="text" size="mini" @click="claimPackage(scope.row)">{{ $t('domestic.forecast') }}</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="statistics-table u-end-flex default-font mt20">
          <div class="mr15">{{ $t('domestic.selection') }}：{{ totalCount.count }}</div>
          <div class="mr15">{{ $t('domestic.addup') }}：{{ totalCount.weight.toFixed(2) }}</div>
          <div class="mr15">{{ $t('domestic.overlength') }}：{{ totalCount.volumeWeight }}</div>
          <div>
            <el-button icon="el-icon-right" type="primary" class="merger-btn" @click="mergeGoods">{{ $t('domestic.combine') }}</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog top="1vh" custom-class="payment-dialog" :title="$t('domestic.combinefleight')" :before-close="closeMerge" :visible.sync="dialogVisible" :close-on-click-modal="false" width="60%">
      <merge-card ref="mergeCard" :merge-data="multipleSelection" @cashier="toCashier"></merge-card>
    </el-dialog>

    <cashier :show.sync="show" :title="$t('mergerCard.cashier')" :order-no="currentOrderNo" @close="closeDialog"></cashier>

    <el-dialog :title="$t('domestic.editinfo')" width="25%" :visible.sync="UpdateVisible">
      <el-form size="mini" :model="ruleForm" :rules="UpdateRules" ref="UpdateRuleForm" label-position="left" label-width="80px">
        <el-form-item :label="$t('domestic.goodsname')" prop="GoodsName">
          <el-input v-model="ruleForm.GoodsName" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('domestic.goodstype')" prop="GoodsTypeName">
          <el-select style="width: 30rem" v-model="ruleForm.GoodsTypeId" :placeholder="$t('domestic.selectgoodstype')">
            <el-option
                v-for="item in GoodsTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('domestic.goodsaccount')" prop="Account">
          <el-input v-model="ruleForm.Account" type="number" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('domestic.goodsprice')" prop="Price">
          <el-input v-model="ruleForm.Price" type="number" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('domestic.clientrem')" prop="ClientRem">
          <el-input v-model="ruleForm.ClientRem" type="textarea" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button size="small" v-loading="loading" type="primary" @click="submitForm('UpdateRuleForm')">{{ $t('domestic.confirmupdate') }}</el-button>
        <el-button size="small" @click="UpdateVisible = false">{{ $t('domestic.cancel') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
        :title="$t('domestic.showImage')"
        :visible.sync="pictureVisible"
        width="40%">
      <el-carousel height="400px">
        <el-carousel-item v-for="(item, i) in images" :key="i">
          <el-image fit="fill" :initial-index="i" :preview-src-list="images" :src="item" class="picture"></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>

    <el-dialog :title="$t('domestic.goodClaim')" width="25%" :visible.sync="claimVisible">
      <el-form :model="claimForm" :rules="claimRules" ref="claimRuleForm" label-position="left" label-width="80px">
        <el-form-item :label="$t('apply.code')" prop="Code">
          <el-input v-model="claimForm.Code" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('apply.goodsname')" prop="GoodsName">
          <el-input v-model="claimForm.GoodsName" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('apply.goodstype')" prop="GoodsTypeId">
          <el-select style="width: 30rem" v-model="claimForm.GoodsTypeId">
            <el-option
                v-for="item in goodTypes"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('apply.account')" prop="Account">
          <el-input v-model="claimForm.Account" @change="handleChange" type="number" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('apply.price')" prop="Price">
          <el-input v-model="claimForm.Price" @change="handleChange" type="number" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('apply.totalprice')" prop="TotalPrice">
          <el-input v-model="claimForm.TotalPrice" disabled type="number" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
        <el-form-item :label="$t('apply.clientrem')" prop="ClientRem">
          <el-input v-model="claimForm.ClientRem" type="text" size="small" autocomplete="off" style="width: 30rem"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: left">
        <el-button v-loading="loading" type="primary" @click="submitForm('claimRuleForm')">{{ $t('domestic.forecast') }}</el-button>
        <el-button @click="claimVisible = false">{{ $t('domestic.cancel') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MergeCard from "@/components/MergeCard.vue";
import { DeletePackages, getPackages, UpdatePackages } from "@/api/member";
import { mapGetters } from "vuex"
import Cashier from "@/components/cashier.vue";
import {getConfig, getGoodsType, parcelForecastSingle} from "@/api/system";
export default {
  data() {
    return {
      queryCode: "",
      pictureVisible: false,
      claimVisible: false,
      activeName: 'all',
      options: {},
      querys: {
        dateRange: []
      },
      activeIndex: 0,
      currentOrderNo: "",
      images: [],
      show: false,
      IsMandatoryForecast: false,
      dialogVisible: false,
      allSelected: false,
      UpdateVisible: false,
      GoodsTypeOptions: [],
      multipleSelection: [],
      ruleForm: {
        GoodsName: "",
        GoodsTypeName: "",
        Account: undefined,
        Price: undefined,
        ClientRem: ""
      },
      claimForm: {
        Account: 1,
        Price: 10,
        TotalPrice: 10
      },
      loading: false,
      tableData: [
      ]
    }
  },
  components: {
    Cashier,
    MergeCard
  },
  computed: {
    ...mapGetters(['userInfo', 'goodTypes', 'TenantId']),
    names() {
      const list = ['all', 'waitPayment', 'arrived', 'unarrive']
      if (this.IsMandatoryForecast) {
        list.push("claim")
      }
      return list
    },
    fields() {
      const tableFields = {}
      tableFields[this.$t('order.id')] = 'Id'
      tableFields[this.$t('goodcard.code')] = 'Code'
      tableFields[this.$t('goodcard.GoodsTypeName')] = 'GoodsTypeName'
      tableFields[this.$t('goodcard.Account')] = 'Account'
      tableFields[this.$t('domestic.warehousename')] = 'WareHouseName'
      tableFields[this.$t('domestic.status')] = 'IsStorage'
      tableFields[this.$t('mergerCard.totalSurcharge')] = 'Surcharge'
      tableFields[this.$t('goodcard.goodsname')] = 'GoodsName'
      tableFields[this.$t('goodcard.weight')] = 'Weight'
      tableFields[this.$t('goodcard.volumeweight')] = 'VolumeWeight'
      return tableFields
    },
    timestampName() {
      const timestamp = Date.now()
      return `${this.$t('order.exportOrder')}${timestamp}.xls`
    },
    UpdateRules() {
      return {
        GoodsName: [
          { required: true, message: this.$t('domestic.UpdateRules.GoodsName.required'), trigger: 'blur' },
        ],
        GoodsTypeName: [
          { required: true, message: this.$t('domestic.UpdateRules.GoodsTypeName.required'), trigger: 'blur' },
        ],
        Account: [
          { required: true, message: this.$t('domestic.UpdateRules.Account.required'), trigger: 'blur' },
        ],
        Price: [
          { required: true, message: this.$t('domestic.UpdateRules.Price.required'), trigger: 'blur' },
        ],
      }
    },
    claimRules() {
      return {
        Code: [{ required: true, message: this.$t('apply.enterExpressNumber'), trigger: 'blur' }],
        GoodsName: [{ required: true, message: this.$t('apply.enterGoodsName'), trigger: 'blur' }],
        GoodsTypeId: [{ required: true, message: this.$t('apply.selectGoodsType'), trigger: 'blur' }],
        Account: [{ required: true, message: this.$t('apply.enterGoodsQuantity'), trigger: 'blur' }],
        Price: [{ required: true, message: this.$t('apply.enterUnitPrice'), trigger: 'blur' }],
        TotalPrice: [{ required: true, message: this.$t('apply.enterTotalPrice'), trigger: 'blur' }]
      }
    },
    totalCount() {
      let count = 0
      let weight = 0
      let volumeWeight = 0
      this.multipleSelection.forEach(item => {
        count += 1
        weight += item.Weight
        volumeWeight += item.VolumeWeight
      })
      return { count, weight, volumeWeight }
    }
  },
  created() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName
    }
    this.getData()
    this.init()
    this.$store.dispatch('webSite/getGoodsType').then(data => {
      this.GoodsTypeOptions = data.map(item => { return { label: item.Name, value: item.Id } })
    })
  },
  watch: {
    activeName() {
      this.getData()
    }
  },
  methods: {
    init() {
      this.$store.dispatch('webSite/getGoodsType').then(data => {
        this.$set(this.options, 'goodsType', data.map(item => {
          return { label: item.Name, value: item.Id }
        }))
      })
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.$set(this.options, 'warehouse', data.map(item => {
          return { label: item.Name, value: item.Id }
        }))
      })
      getConfig({ TenantId: this.TenantId}).then(response => {
        const { IsMandatoryForecast } = response.Data
        this.IsMandatoryForecast = IsMandatoryForecast
      })
      this.$forceUpdate()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    claimPackage() {
      this.getGoodsType()
      this.claimForm = {
        Account: 1,
        Price: 10,
        TotalPrice: 10
      }
      this.claimVisible = true
    },
    handleChange() {
      this.claimForm.TotalPrice = parseInt(this.claimForm.Account) * parseInt(this.claimForm.Price)
      this.$forceUpdate()
    },
    getGoodsType() {
      const data = {
        pageIndex: 1,
        pageRows: 10
      }
      getGoodsType(data).then(response => {
        const { Data } = response;
        this.$store.dispatch('consolidation/changeGoodTypes', Data)
      })
    },
    checkSelectable(row) {
      if (this.IsMandatoryForecast) {
        return row.IsStorage && !row.IsProblem && row.IsForecast
      } else {
        return row.IsStorage && !row.IsProblem
      }
    },
    showImage(row) {
      this.images = row.Images;
      this.pictureVisible = true
    },
    closeDialog() {
      this.show = false
      this.$router.push({
        path: '/member/order-domestic'
      })
    },
    toCashier(orderNo) {
      this.currentOrderNo = orderNo;
      this.dialogVisible = false;
      this.show = true
    },
    handleClick(event) {
      this.activeIndex = event.index;
    },
    closeMerge() {
      this.dialogVisible = false
    },
    editGood(row) {
      this.ruleForm = Object.assign({}, row)
      this.UpdateVisible = true;
    },
    deleteGood(row) {
      this.$confirm(
          this.$t('domestic.confirmDelete', { goodsName: row.GoodsName }),
          this.$t('domestic.prompt'),
          {
            confirmButtonText: this.$t('domestic.confirm'),
            cancelButtonText: this.$t('domestic.cancel'),
            type: 'warning'
          }
      )
          .then(() => {
            const data = [row.Id];
            DeletePackages(data)
                .then(response => {
                  if (response.Success) {
                    this.$message({
                      type: 'success',
                      message: this.$t('domestic.deleteSuccess')
                    });
                    this.getData();
                  }
                })
                .catch(() => {});
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('domestic.cancelDelete')
            });
          });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          if (formName === 'UpdateRuleForm') {
            const data = {
              Id: this.ruleForm.Id,
              GoodsName: this.ruleForm.GoodsName,
              GoodsTypeId: this.ruleForm.GoodsTypeId,
              Account: this.ruleForm.Account,
              Price: this.ruleForm.Price,
              ClientRem: this.ruleForm.ClientRem,
              Ver: this.ruleForm.Ver
            };
            UpdatePackages(data)
                .then(response => {
                  this.loading = false;
                  if (response.Success) {
                    this.$message({
                      type: 'success',
                      message: this.$t('domestic.updateSuccess')
                    });
                    this.getData();
                    this.UpdateVisible = false;
                  } else {
                    this.$message({
                      type: 'warning',
                      message: response.Msg
                    });
                  }
                })
                .catch(() => {
                  this.loading = false;
                });
          } else {
            const data = { clientParcelForecastSingleInfos: [this.claimForm] }
            parcelForecastSingle(data).then(response => {
              this.loading = false;
              const { Code, Msg, Success } = response
              if (Code === 200 && Success) {
                this.$message({
                  type: 'success',
                  message: this.$t('apply.reportSuccess')
                });
                this.getData();
                this.claimVisible = false
              } else {
                this.$message({
                  type: 'warning',
                  message: Msg
                });
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    mergeGoods() {
      if (!this.multipleSelection.length) {
        this.$message({
          type: 'warning',
          message: this.$t('domestic.selectMergePackages')
        });
        return false;
      }
      if (Array.from(new Set(this.multipleSelection.map(item => item.WareHouseId))).length > 1) {
        this.$message({
          type: 'warning',
          message: this.$t('domestic.diffWarehouse')
        });
        return false;
      }
      if (this.multipleSelection.some(item => item.IsStorage === false)) {
        this.$message({
          type: 'warning',
          message: this.$t('domestic.unstoredGoods')
        });
        return false;
      }
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.mergeCard.resetForm()
      })
    },
    getData() {
      let QueryCodes = this.queryCode ? this.queryCode.split(/\s+/) : undefined
      const data = {
        PageIndex: 1,
        PageRows: 500,
        ClientId: this.userInfo.Id,
        IsOrder: 1,
        QueryCodes: QueryCodes,
        QueryCodeType: 7,
        GoodsTypeId: this.querys.goodsType || undefined,
        WareHouseId: this.querys.warehouse || undefined,
        StartTime: this.querys.dateRange.length ? this.querys.dateRange[0] : undefined,
        EndTime: this.querys.dateRange.length ? this.querys.dateRange[1] : undefined,
      }
      if (this.activeName === 'arrived') {
        data['IsStorage'] = 2
      } else if (this.activeName === 'unarrive') {
        data['IsStorage'] = 1
      } else if (this.activeName === 'all') {
        data['IsStorage'] = 0
      } else if (this.activeName === 'waitPayment') {
        data['IsStorage'] = 2
        data['IsForecast'] = this.IsMandatoryForecast ? 2 : 0
      } else {
        data['IsForecast'] = 1
        data['IsStorage'] = 2
      }
      this.loading = true
      getPackages(data).then(response => {
        this.loading = false
        this.tableData = response.Data;
        this.allSelected = false;
      })
    }
  }
}

</script>

<style scoped lang="scss">
.picture {
  width: 100%;
}
::v-deep .payment-dialog {
  margin: 0 auto;
}
.default-font {
  font-size: 13px;
}
.consolidated-container {
  padding: 2rem 2.5rem;
  .queryItems {
    display: flex;
    align-items: center;
  }
  .merger-btn {
    background-color: #194D8F;
    width: 16rem;
  }
  .arrived-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #19B14C;
    border-radius: 2px;
    color: #ffffff;
    font-size: 1.4rem;
  }
  .unarrive-box {
    margin: 0 auto;
    width: 7.5rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background: #ED4014;
    border-radius: 2px;
    color: #ffffff;
    font-size: 1.4rem;
  }
  .back-btn {
    width: 16rem;
  }
  .sub-btn {
    background-color: #fff;
    width: 16rem;
  }
}
</style>
