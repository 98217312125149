<template>
  <div class="shopee-container">
    <div class="queryItems mb10">
      <el-input v-model="shopName" size="mini" class="mr15" clearable style="width: 30rem" placeholder="店铺名称"></el-input>
      <el-button size="mini" type="primary">查询</el-button>
      <el-button size="mini" type="primary" @click="onNav">授权</el-button>
    </div>
    <el-table
        :data="listData"
        border
        style="width: 100%"
        v-loading="loading"
    >
      <el-table-column :label="$t('order.warehouse')" prop="WareHouseName" align="center"></el-table-column>
      <el-table-column :label="$t('order.carrier')" prop="CarrierName" align="center"></el-table-column>
      <el-table-column :label="$t('order.timer')" prop="TimerName" align="center"></el-table-column>
      <el-table-column :label="$t('order.goodstype')" prop="GoodsTypeName" align="center"></el-table-column>
      <el-table-column :label="$t('order.Weight')" prop="Weight" align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>

import {defineComponent} from "vue";

export default defineComponent({
  data() {
    return {
      shopName: "",
      listData: [],
      loading: false
    }
  },
  methods: {
    onNav() {
      this.$message.warning("未开通申请虾皮开发者账号，请前往虾皮官方申请开通再试")
    }
  }
})
</script>



<style scoped lang="scss">
::v-deep .el-table__cell {
  padding: 0.8rem 0;
}
::v-deep .el-table .cell {
  font-size: 1.4rem;
}
.shopee-container {
  padding: 2rem 2.5rem;
  .queryItems {
    display: flex;
    align-items: center;
  }
}
</style>